import MemoAllNews from "@assets/SVG/NewsFeed/AllNews";
import { BookmarkIcon } from "@heroicons/react/24/outline";
import { Dispatch, SetStateAction } from "react";
import { CopyToClipboardState } from "react-use/lib/useCopyToClipboard";
import { searchByTagsPayload } from "@/services/search";
import { IHookStateSetAction } from "react-use/lib/misc/hookState";
import { DISPLAY, News } from "@/types/api";

export enum Mode {
    Annually = "Annually",
    Monthly = "Monthly",
    Coupon = "Coupon",
}

export enum UpgradeSection {
    Filter_Creation = "Filter Creation",
    Filter_Customization = "Filter Customization",
    Search = "Search",
    Close_Ad = "Close Ad",
    Watchlist = "Watchlist",
    Bookmark = "Bookmark",
}

export const unwantedAssets = ["Wormhole", "Mantra", "Pixels", "OIN Finance"];

export enum StripeStatus {
    Open = "open",
    Complete = "complete",
}

export enum cardTypes {
    AMEX = "amex",
    VISA = "visa",
    MASTERCARD = "mastercard",
    DISCOVER = "discover",
    DINERS = "diners",
    UNIONPAY = "unionpay",
    JCB = "jcb",
}

export interface FilterSet {
    filterIndex: string;
    filterName: string;
    hideAssetNews?: boolean;
    lowQualityNews: boolean;
    showPrimarySourcesOnly: boolean;
    showBlockbeatNews: boolean;
    showTwitterNews: boolean;
    showRedditNews: boolean;
    showNewsNews: boolean;
    showPressRelease: boolean;
    showGovernmentPress: boolean;
    showBlogNews: boolean;
    showYouTubeNews: boolean;
    showPodcastNews: boolean;
    coinFilter?: string;
    icon?: any;
}

export interface NewsFeedContextValue {
    alertMessage: string;
    allFilterSets: FilterSet[];
    copy: (value: string) => void;
    copyValue: CopyToClipboardState;
    display: DISPLAY;
    fieldsPayload: searchByTagsPayload;
    filterSet: FilterSet;
    filterSets: FilterSet[];
    // fieldsSearchQuery: string[];
    hideResults: boolean;
    newsIndex: number; // currently selected index
    newsItem: News;
    openFilterModal: boolean;
    setDisplay: Dispatch<SetStateAction<DISPLAY>>;
    setFilterSet: Dispatch<SetStateAction<FilterSet>>;
    setFilterSets: Dispatch<SetStateAction<FilterSet[]>>;
    setOpenFilterModal: Dispatch<SetStateAction<boolean>>;
    showScrollToTopButton: boolean;
    setShowScrollToTopButton: Dispatch<SetStateAction<boolean>>;
    scrollToTop: boolean;
    setScrollToTop: Dispatch<SetStateAction<boolean>>;
    setNewsIndex: (idx: number) => void;
    setNewsItem: Dispatch<SetStateAction<News>>;
    showAlert: boolean;
    setShowAlert: Dispatch<SetStateAction<boolean>>;
    setAlertMessage: Dispatch<SetStateAction<string>>;
    showShare: boolean;
    setShowShare: Dispatch<SetStateAction<boolean>>;
    showSearchNewslist: boolean;
    setShowSearchNewslist: Dispatch<SetStateAction<boolean>>;
    // setPayload: Dispatch<SetStateAction<searchByTagsPayload>>;
    setFieldsPayload: Dispatch<SetStateAction<searchByTagsPayload>>;
    searchQuery: string[];
    setSearchQuery: Dispatch<SetStateAction<string[]>>;
    // setFieldsSearchQuery: Dispatch<SetStateAction<string[]>>;
    searchQueryCategory: string[];
    setSearchQueryCategory: Dispatch<SetStateAction<string[]>>;
    setSearchNewsList: Dispatch<SetStateAction<any[]>>;
    loading: boolean;
    setLoading: Dispatch<SetStateAction<boolean>>;
    setNewSearchedNews: (newList: IHookStateSetAction<News[]>) => void;
    newsLoading: boolean;
    setNewsLoading: Dispatch<SetStateAction<boolean>>;
    setHideResults: (nextValue?: any) => void;
    renderedNews: News[];
    setRenderedNews: Dispatch<SetStateAction<News[]>>;
    loadMoreItems: () => Promise<any>;
    renderedResults: News[];
    neededFilterSet: string[];
    timeRange: number[];
    setTimeRange: Dispatch<SetStateAction<number[]>>;
    newsLengthFromSource: number;
    setNewsLengthFromSource: Dispatch<SetStateAction<number>>;
    filterSetChanged: boolean;
    setFilterSetChanged: Dispatch<SetStateAction<boolean>>;
    showResultsBanner: boolean;
    setShowResultsBanner: Dispatch<SetStateAction<boolean>>;
    showUpgradeModal: boolean;
    setShowUpgradeModal: Dispatch<SetStateAction<boolean>>;
}

export const defaultFilterSets: FilterSet[] = [
    {
        filterIndex: "all",
        filterName: "All News",
        hideAssetNews: false,
        lowQualityNews: false,
        showPrimarySourcesOnly: false,
        showBlockbeatNews: true,
        showTwitterNews: true,
        showRedditNews: true,
        showNewsNews: true,
        showPressRelease: true,
        showGovernmentPress: true,
        showBlogNews: true,
        showYouTubeNews: true,
        showPodcastNews: true,
        coinFilter: "All Coins",
        icon: MemoAllNews,
    },
    // {
    //     filterIndex: "important",
    //     filterName: "Important",
    //     hideAssetNews: false,
    //     lowQualityNews: false,
    //     showPrimarySourcesOnly: false,
    //     showBlockbeatNews: true,
    //     showTwitterNews: true,
    //     showRedditNews: true,
    //     showNewsNews: true,
    //     showPressRelease: true,
    //     showGovernmentPress: true,
    //     showBlogNews: true,
    //     showYouTubeNews: true,
    //     showPodcastNews: true,
    //     coinFilter: "All Coins",
    //     icon: MemoExclamation,
    // },
    // {
    //     filterIndex: "unread",
    //     filterName: "Unread",
    //     hideAssetNews: false,
    //     lowQualityNews: false,
    //     showPrimarySourcesOnly: false,
    //     showBlockbeatNews: true,
    //     showTwitterNews: true,
    //     showRedditNews: true,
    //     showNewsNews: true,
    //     showPressRelease: true,
    //     showGovernmentPress: true,
    //     showBlogNews: true,
    //     showYouTubeNews: true,
    //     showPodcastNews: true,
    //     coinFilter: "All Coins",
    //     icon: ListBulletIcon,
    // },
    // {
    //     filterIndex: "hot",
    //     filterName: "Hot",
    //     hideAssetNews: false,
    //     lowQualityNews: false,
    //     showPrimarySourcesOnly: false,
    //     showBlockbeatNews: true,
    //     showTwitterNews: true,
    //     showRedditNews: true,
    //     showNewsNews: true,
    //     showPressRelease: true,
    //     showGovernmentPress: true,
    //     showBlogNews: true,
    //     showYouTubeNews: true,
    //     showPodcastNews: true,
    //     coinFilter: "All Coins",
    //     icon: FireIcon,
    // },
    {
        filterIndex: "saved",
        filterName: "Saved",
        hideAssetNews: false,
        lowQualityNews: false,
        showPrimarySourcesOnly: false,
        showBlockbeatNews: true,
        showTwitterNews: true,
        showRedditNews: true,
        showNewsNews: true,
        showPressRelease: true,
        showGovernmentPress: true,
        showBlogNews: true,
        showYouTubeNews: true,
        showPodcastNews: true,
        coinFilter: "All Coins",
        icon: BookmarkIcon,
    },
];

export const activeFilters = [
    "Blog",
    "Government",
    "News",
    "Press Release",
    "Reddit",
    "Twitter",
    // "Medium",
    "BlockBeat",
    "Podcast",
    "YouTube",
];

export const companiesInvolvedInCrypto = [
    "10T Holdings",
    "1confirmation",
    "Abbvie",
    "Adobe",
    "Advanced Bitcoin Tech",
    "Aker",
    "Alameda",
    "Alibaba",
    "Alphabet",
    "Amazon",
    "Anheuser-Busch",
    "Apple",
    "Arrington XRP Capital",
    "BHP",
    "BIGG Digital Assets",
    "BIT Mining Ltd.",
    "BXE Capital",
    "Bastion",
    "Benchmark Capital",
    "Bit Digital Inc.",
    "Bitbull Capital",
    "Bitfinex",
    "Bithumb",
    "Bitstamp",
    "Bittrex",
    "Bitwise Asset Mangement",
    "Block Force Capital",
    "BlockQuarry Corp",
    "Blockchain Capital",
    "Blockseed Capital",
    "Blocktower Capital",
    "Blockwealth Capital",
    "Bosch",
    "ByBit",
    "Canaan Inc.",
    "Charter",
    "Chevron",
    "Chromatic Capital",
    "Circle",
    "Cisco",
    "Citi Bank",
    "Coin Capital",
    "Coin Citadel",
    "Coinbase",
    "Coinbase Ventures",
    "Comcast",
    "Crypto Capital Investments",
    "Crypto.com",
    "Cypherpunk Holdings",
    "Daimler",
    "Delphi Digital",
    "Digital Capital Mangement",
    "Digital Currency Group",
    "DigitalX",
    "Distributed Global",
    "DocuSign",
    "DragonFly",
    "Draper Associates",
    "Exigent Capital",
    "FBG Capital",
    "FRMO Corp",
    "FTX",
    "Fortress Blockchain",
    "GT Capital",
    "GV",
    "Galaxy Digital",
    "Galois Capital",
    "Gamestop",
    "Gemini",
    "Genesis Block Capital",
    "Genesis Trading",
    "Gordian Block Capital",
    "Grasshopper Capital",
    "Grayscale",
    "GreenBox POS",
    "HIVE Blockchain Techno",
    "Hermes",
    "Hive Blockchain",
    "Honeywell",
    "Huobi",
    "Hut 8",
    "IBM",
    "Intel",
    "Intuit",
    "Iterative Capital Management",
    "JP Morgan",
    "Jane St.",
    "Jefferson Capital",
    "Johnson & Johnson",
    "Jump",
    "Kine Protocol",
    "Kraken",
    "Kucoin",
    "LDGR Capital Management",
    "LDJ Capital INC",
    "LQwD FinTech Corp",
    "LVMH",
    "Ledgerprime",
    "Linde",
    "Loreal",
    "Lowes",
    "MC Capital Ventures",
    "MEXC",
    "Manifold Trading",
    "Marathon Digital",
    "Mastercard",
    "Mechanism Capital",
    "Meitu",
    "Meta",
    "MetaStable",
    "Metro Mile",
    "MicroStrategy",
    "Microsoft",
    "Mode Global Holdings",
    "Moderna",
    "Mogo",
    "Multicoin Capital",
    "Neptune Digital Assets",
    "Nestle",
    "Neural Capital LLC",
    "Newblock Capital",
    "Nexon Co",
    "Nike",
    "Nvidia",
    "OKX",
    "Odin88",
    "Off The Chain Capital",
    "Oracle",
    "Oslodotcom",
    "P&G",
    "Pancake Swap",
    "Pantera Capital",
    "Paradigm",
    "Parallax Digital",
    "PayPal",
    "Pepsico",
    "Pfizer",
    "Polychain",
    "Polynexus Capital",
    "ProBit",
    "Protocol Ventures",
    "QCP Capital",
    "Qualcomm",
    "RF Capital",
    "Reality Shares inc",
    "Red Leaf",
    "Riot Blockchain",
    "Roche",
    "SAP",
    "Sales Force",
    "Samsung",
    "Satoshi Capital Advisors",
    "Scalar Capital",
    "Selini Capital",
    "Sequoia Capital",
    "Serrada Capital LLC (might be inactive)",
    "Shell",
    "Shopify",
    "Silver 8 Capital (mgiht be closed)",
    "Square",
    "StableNode",
    "Stronghold Digital",
    "Sushi Swap",
    "Systematic Alpha Management",
    "Taureon Capital",
    "Tencent",
    "Tetras Capital",
    "The Brooker Group",
    "The Home Depot",
    "Tingo Inc",
    "Toyota",
    "Trader Joe",
    "Typhon Capital",
    "UPS",
    "Union Pacific",
    "Uniswap",
    "United Health Group",
    "Vellum Capital LLC",
    "Verizon",
    "Virgil Capital",
    "Visa",
    "Voyager Digital",
    "Walden Bridge Capital",
    "Walmart",
    "Walt Disney",
    "Wave Finanical",
    "Well Fargo",
    "Wintermute",
    "XRAM Capital",
    "Zero-One-Capital",
    "a16z",
    "dYdX",
    "eGirl Capital",
    "mgnr",
];

export const categories = ["Regulatory", "Lawsuit", "Partnerships", "DEFI", "NFTs", "Metaverse", "Staking"];

export const people = [
    "0xMaki",
    "19Keys",
    "3LAU",
    "Abigail Johnson",
    "Adam Back",
    "Akon",
    "Alessandro Chiesa",
    "Alex Gluchowski",
    "Alex Mashinsky",
    "Alex Tapscott",
    "Alex Wilson",
    "Alexandre Dreyfus",
    "Alexey Pertsev",
    "Alexis Ohanian",
    "Analisa Torres",
    "Anatoly Yakovenko",
    "Andre Cronje",
    "Andreas Antonopoulos",
    "Andrew Bragg",
    "Andrew Levine",
    "Andrew Yang",
    "Andy Cheung",
    "Anil Lulla",
    "Anthony Albanese",
    "Anthony Pompliano",
    "Anthony Scaramucci",
    "Anton Bukov",
    "Antonio Juliano",
    "Arianna Simpson",
    "Arthur Hayes",
    "Balaji Srinivasan",
    "Barry Silbert",
    "Bart Stephens",
    "Beeple",
    "Bob Loukas",
    "Bobby Lee",
    "Bobby Ong",
    "Brad Garlinghouse",
    "Brandon Chez",
    "Brendan Eich",
    "Brian Armstrong",
    "Brian Behlendorf",
    "Brian Brooks",
    "Brittany Kaiser",
    "Brock Pierce",
    "Bryan Bishop",
    "Cameron Winklevoss",
    "Camila Russo",
    "Cathie Wood",
    "Changpeng Zhao",
    "Charles Cascarilla",
    "Charles Hoskinson",
    "Charlie Lee",
    "Charlie Shrem",
    "Chris Larsen",
    "Christine Lagarde",
    "Cobie",
    "CryptoBirb",
    "CryptoPunks",
    "Cuy Sheffield",
    "Cynthia Lummis",
    "Da Hongfei",
    "Damien Hirst",
    "Dan Boneh",
    "Dan Held",
    "Dan Morehead",
    "Dan Schatt",
    "Dan Schulman",
    "Daniel Larimer",
    "Danny Ryan",
    "David Chaum",
    "David Marcus",
    "Dawn Song",
    "Decentralized Exchanges",
    "Degen Spartan",
    "Denelle Dixon",
    "Devin Finzer",
    "Do Kwon",
    "Dominik Schiener",
    "Don Tapscott",
    "Edward Moncada",
    "Eli Ben-Sasson",
    "Elizabeth Stark",
    "Elon Musk",
    "Elon Musk and Crypto Twitter",
    "Emilie Choi",
    "Emin Gün Sirer",
    "Epic Games",
    "Eric Larchevêque",
    "Erik Voorhees",
    "Esteban Ordano",
    "Eugenia Kuyda",
    "Francis Suarez",
    "Frank DeGods",
    "Fred Ehrsam",
    "Gary Gensler",
    "Gary Vaynerchuk",
    "Gavin Andresen",
    "Gavin Wood",
    "Good Ol’ Bitcoin",
    "Green Tokens",
    "Hany Rashwan",
    "Hardware Wallets",
    "Hayden Adams",
    "Heath Tarbert",
    "Helen Hai",
    "Hester Peirce",
    "JL Van Der Velde",
    "Jack Dorsey",
    "Jaime Leverton",
    "Jake Chervinsky",
    "Jameson Lopp",
    "Jason Les",
    "Jason Williams",
    "Jay Hao",
    "Jed McCaleb",
    "Jeff Garzik",
    "Jeremy Allaire",
    "Jesse Powell",
    "Jihan Wu",
    "Jimmy Song",
    "Joe DiPasquale",
    "Joey Krug",
    "John McAfee",
    "Joseph Lubin",
    "Joseph Pallant",
    "Joshua Frank",
    "Juan Benet",
    "Justin Sun",
    "Kain Warwick",
    "Kathleen Breitman",
    "Kathryn Haun",
    "Keith Grossman",
    "Kelly Loeffler",
    "Kerry Wong",
    "Kevin Abosch",
    "Kevin Chou",
    "Ki Young Ju",
    "Kieran Warwick",
    "Kimbal Musk",
    "Kris Marszalek",
    "Kristin Smith",
    "Kyle Kistner",
    "Laura Shin",
    "Layne Lafrance",
    "Lazarus Group",
    "Leah Wald",
    "Leigh Cuen",
    "Leon Li",
    "Linda Xie",
    "Lisa Loud",
    "Lyn Alden Schwartzer",
    "Mance Harmon",
    "Marc Andreessen",
    "Marco Santori",
    "Maria Sharapova",
    "Mark Cuban",
    "Mark Yusko",
    "Marta Piekarska",
    "Marwan Alzarouni",
    "Masayoshi Son",
    "Mati Greenspan",
    "Matt Corallo",
    "Matt Kane",
    "Matthew Roszak",
    "Meltem Demirors",
    "Michael Anderson",
    "Michael Arrington",
    "Michael Egorov",
    "Michael Novogratz",
    "Michael Sapir",
    "Michael Saylor",
    "Michael Shaulov",
    "Michael Sonnenshein",
    "Michael Terpin",
    "Mike Belshe",
    "Mike Dudas",
    "Mike Kayamori",
    "Mike Novogratz",
    "Mitchell Amador",
    "Molly Wintermute",
    "Muneeb Ali",
    "Museum of Crypto Art",
    "Nayib Bukele",
    "Neil Shen",
    "Nejc Kodrič",
    "Nick Johnson",
    "Nick Szabo",
    "Nicole Muniz",
    "Nuseir Yassin",
    "PancakeSwap Chefs",
    "Paolo Ardoino",
    "Pat Duffy",
    "Paul Tudor Jones",
    "Pavel Durov",
    "Peng Zhong",
    "Pentoshi",
    "Perianne Boring",
    "Peter McCormack",
    "Peter Smith",
    "Pieter Wuille",
    "PlanB",
    "Preston Byrne",
    "Ran Neuner",
    "Raoul Pal",
    "Ray Youssef",
    "Ria Bhutoria",
    "Riccardo Spagni",
    "Rishi Sunak",
    "Robert Leshner",
    "Roger Ver",
    "Roham Gharegozlou",
    "Roneil Rumburg",
    "Ronghui Gu",
    "Rostin Behnam",
    "Rune Christensen",
    "Ryan Selkis",
    "Sam Altman",
    "Sam Bankman Fried",
    "Sam Bankman-Fried",
    "Sam Bankman-Fried and Caroline Ellison",
    "Samczsun",
    "Samson Mow",
    "Sandeep Nailwal",
    "Sanja Kon",
    "Satohsi Nakamoto",
    "Scott Melker",
    "Sebastien Borget",
    "Serena Tabacchi",
    "Sergey Nazarov",
    "Sheila Warren",
    "Shiba dog",
    "Snoop Dogg",
    "Soulbound Tokens",
    "Spencer Bogart",
    "Spencer Dinwiddie",
    "Stani Kulechov",
    "Star Xu",
    "Stephen Pair",
    "Steven Goldfeder",
    "Steven Kokinos",
    "Steven Vasilev",
    "Su Zhu",
    "Su Zhu and Alex Mashinsky",
    "Sunny King",
    "Sunny Lu",
    "Ted Livingston",
    "Teodora Atanasova",
    "The Crypto Dog",
    "The Hermitage",
    "The Metaverse",
    "The Open Network",
    "Thomas Walton-Pocock",
    "Tim Draper",
    "Tom Brady",
    "Tom Emmer",
    "Trace Mayer",
    "Trippy",
    "Trung Nguyen",
    "Tuur Demeester",
    "Tyler Winklevoss",
    "Vanessa Grellet",
    "Vasil Hard Fork",
    "Vitalik Buterin",
    "Vlad Tenev",
    "Vlad Zamfir",
    "Wes Geisenberger",
    "WhalePanda",
    "William Shatner",
    "Willy Woo",
    "Winklevoss Twins",
    "Xi Jinping",
    "Yat Siu",
    "Yoni Assia",
    "Yves La Rose",
    "ZachXBT",
    "Zooko Wilcox",
    "Zooko Wilcox-O'Hearn",
];

export function getArticleSource(source: string) {
    switch (source) {
        case "DOJ":
            return "Department of Justice Press Release";
        case "economictimes.indiatimes.com":
            return "Economic Times";
        case "protos.com":
            return "Protos";
        case "finextra.com":
            return "Finextra";
        case "news.bloomberglaw.com":
            return "Bloomberg Law";
        case "uk.finance.yahoo.com":
            return "Yahoo Finance UK";
        case "bravenewcoin.com":
            return "Brave News Coin";
        case "news.bloombergtax.com":
            return "Bloomberg Tax";
        case "bitcoinist.com":
            return "Bitcoinist";
        case "bitcoinworld.co.in":
            return "Bitcoin World";
        case "Bitrates.com":
            return "Bitrates";
        case "bnnbloomberg.ca":
            return "BNN Bloomberg";
        case "blockonomi.com":
            return "Blockonomi";
        case "benzinga.com":
            return "Benzinga";
        case "barrons.com":
            return "Barrons";
        case "bryannewsplace.com":
            return "Bryan News Place";
        case "blockworks.co":
            return "BlockWorks";
        case "ambcrypto.com":
            return "AM BCrypto";
        case "agweek.com":
            return "Agweek";
        case "coingape.com":
            return "CoinGape";
        case "cryptosaurus.tech":
            return "Crypto Saurus";
        case "cryptodaily.co.uk ":
            return "CryptoDaily";
        case "ccn.com":
            return "CCN";
        case "coindesk.com":
            return "CoinDesk";
        case "cryptopolitan.com":
            return "Cryptopolitan";
        case "coincodecap.com":
            return "CoinCodeCap";
        case "coinpedia.org":
            return "CoinPedia";
        case "cryptoglobe.com":
            return "Crypto Globe";
        case "cryptopotato.com":
            return "Crypto Potato";
        case "cryptosheadlines.com":
            return "Cryptos Headlines";
        case "cointelegraph.com":
            return "Cointelegraph";
        case "cryptotimes.io":
            return "The Crypto Times";
        case "crypto.news":
            return "crypto.news";
        case "coincodex.com":
            return "CoinCodex";
        case "cryptoslate.com":
            return "CryptoSlate";
        case "coinjournal.net":
            return "CoinJournal";
        case "coinspeaker.com":
            return "Coinspeaker";
        case "crypto-news-flash.com":
            return "Crypto News Flash";
        case "cryptobriefing.com":
            return "Cryipto Brieifing";
        case "coininsider.com":
            return "Coin Insider";
        case "crainnext.com":
            return "Crain Next";
        case "cnbc.com":
            return "CNBC";
        case "cftc.gov":
            return "CFTC";
        case "cleveland.com":
            return "Cleveland.com";
        case "coindoo.com":
            return "Coindoo";
        case "Cointia.com":
            return "Cointia";
        case "crowdfundinsider.com":
            return "Crowdfund Insider";
        case "cryptonews.com":
            return "Crypto News";
        case "deltahedged.com":
            return "Delta Hedged";
        case "dailycoin.com":
            return "DailyCoin";
        case "decrypt.co":
            return "Decrypt";
        case "dailyhodl.com":
            return "THE DAILY HODL";
        case "en.coin-turk.com":
            return "COINTURK NEWS";
        case "econotimes.com":
            return "EconoTimes";
        case "forbes.com":
            return "Forbes";
        case "finbold.com":
            return "Finbold";
        case "finance.yahoo.com":
            return "Yahoo Finance";
        case "ft.com":
            return "The Financial Times";
        case "financemagnates.com":
            return "Finance Magnates";
        case "marketwatch.com":
            return "MarketWatch";
        case "markets.businessinsider.com":
            return "Markets Insider";
        case "nasdaq.com":
            return "Nasdaq";
        case "newsbtc.com":
            return "NewsBTC";
        case "nbcconnecticut.com":
            return "NBC Connecticut";
        case "news.bitcoin.com":
            return "Bitcoin.com";
        case "optimus.com":
            return "Optimus";
        case "qz.com":
            return "Quartz";
        case "reuters.com":
            return "Reuters";
        case "seekingalpha.com":
            return "Seeking Alpha";
        case "scmp.com":
            return "South China Morning Post";
        case "sec.gov":
            return "SEC";
        case "timesunion.com":
            return "Times Union";
        case "thedefiant.io":
            return "The Defiant";
        case "trustnodes.com":
            return "Trustnodes";
        case "tokenist.com":
            return "The Tokenist";
        case "thecoinrepublic.com":
            return "The Coin Republic";
        case "u.today":
            return "U Today";
        case "unlock-bc.com":
            return "Unlock Blockchain";
        case "uk.sports.yahoo.com":
            return "Yahoo Sport";
        case "uk.news.yahoo.com":
            return "Yahoo News";
        case "Watcher.guru":
            return "Watcher Guru";
        case "wsj.com":
            return "The Wall Street Journal";
        case "wzifah.com":
            return "Wzifah";
        case "whdh.com":
            return "WHDH 7News";
        case "zycrypto.com":
            return "ZyCrypto";
        case "livebitcoinnews.com":
            return "Live Bitcoin News";
        case "Bitcoin.com":
            return "Bitcoin.com";
        case "medium.com":
            return "Medium";
        case "reddit.com":
            return "Reddit";
        case "www.youtube.com":
            return "YouTube";
        case "youtube.com":
            return "YouTube";
        case "twitter.com":
            return "Twitter";
        case "blockbeat.io":
            return "BlockBeat";
        case "beincrypto.com":
            return "Be In Crypto";
        case "cryptodaily.co.uk":
            return "Crypto Daily";
        case "en.ethereumworldnews.com":
            return "ETH World News";
        case "forkast.news":
            return "Forkast News";
        case "theblock.co":
            return "The Block";
        case "bitcoinmagazine.com":
            return "Bitcoin Magazine";
        case "gokhshteinmedia.com":
            return "Gokhshtein Media";
        case "Blokt.co":
            return "Blokt";
        case "Bravenewcoin.com":
            return "Brave New Coin";
        case "Coindol.com":
            return "Coin Dol";
        case "cointia.com":
            return "Cointia";
        case "watcher.guru":
            return "Watcher Guru";
        case "protos.com/":
            return "Protos";
        case "cryptocurrencywire.com":
            return "Crypto Currency Wire";
        case "wu-talk.com":
            return "Wu Talk";
        case "blockchain.news":
            return "Blockchain News";
        case "optimisus.com":
            return "Optimisus";
        case "thenewscrypto.com":
            return "The News Crypto";
        case "luno.com":
            return "Luno";
        case "quillette.com":
            return "Quillette";
        case "rasmussenreports.com":
            return "Rasmussen Reports";
        case "redstate.com":
            return "RedState";
        case "revolver.news":
            return "Revolver News";
        case "richmond.com":
            return "Richmond Times-Dispatch";
        case "rsbnetwork.com":
            return "Right Side Broadcasting Network (RSBN)";
        case "rightwingnews.com":
            return "John Hawkins' Right Wing News";
        case "rt.com":
            return "RT News";
        case "scriberrnews.com":
            return "Scriberr News";
        case "spiked-online.com":
            return "spiked - humanity is underrated";
        case "theamericanconservative.com":
            return "The American Conservative";
        case "americanmind.org":
            return "The American Mind";
        case "spectator.org":
            return "The American Spectator";
        case "thebulwark.com":
            return "The Bulwark";
        case "dailysignal.com":
            return "The Daily Signal";
        case "thedispatch.com":
            return "The Dispatch";
        case "thegatewaypundit.com":
            return "The Gateway Pundit";
        case "theimaginativeconservative.org":
            return "The Imaginative Conservative";
        case "thelibertarianrepublic.com":
            return "The Libertarian Republic";
        case "thenationalpulse.com":
            return "The National Pulse";
        case "thepostmillennial.com":
            return "The Post Millennial";
        case "pe.com":
            return "The Press-Enterprise";
        case "waldo.villagesoup.com":
            return "Waldo County VillageSoup";
        case "thefirsttv.com":
            return "The First TV";
        case "thetexan.news":
            return "The Texan";
        case "westernjournal.com":
            return "The Western Journal";
        case "townhall.com":
            return "Townhall";
        case "upward.news":
            return "Upward News";
        case "freebeacon.com":
            return "Free Beacon";
        case "thecentersquare.com":
            return "The Center Square";
        case "bizpacreview.com":
            return "BizPac Review";
        case "foxbusiness.com":
            return "Fox Business";
        case "babylonbee.com":
            return "Babylon Be";
        case "deseret.com":
            return "Deseret News";
        case "fortune.com":
            return "Fortune";
        case "brownstone.org":
            return "Brownstone Institute";
        case "tabletmag.com":
            return "Tablet Magazine";
        case "thecollegefix.com":
            return "The College Fix";
        case "policetribune.com":
            return "The Police Tribune";
        case "wnd.com":
            return "WND";
        case "christianitytoday.com":
            return "Christianity Today";
        case "humanevents.com":
            return "Human Events";
        case "christianpost.com":
            return "Christian Post";
        case "observer.com":
            return "Observer";
        case "tatumreport.com":
            return "The Tatum Report";
        case "twitchy.com":
            return "Twitchy";
        case "populistwire.com":
            return "Populist Wire";
        case "farleftwatch.com":
            return "Far Left Watch";
        case "nationalpost.com":
            return "National Post";
        case "zerohedge.com":
            return "Zerohedge";
        case "themichiganstar.com":
            return "The Michigan Star";
        case "floridacapitalstar.com":
            return "The Florida Capital Star";
        case "iowatorch.com":
            return "The Iowa Torch";
        case "ocregister.com":
            return "Orange County Register";
        case "apnews.com":
            return "Associated Press News";
        case "axios.com":
            return "Axios";
        case "bbc.com":
            return "BBC";
        case "csmonitor.com":
            return "Christian Science Monitor";
        case "newsweek.com":
            return "Newsweek";
        case "thehill.com":
            return "The Hill";
        case "baltimoresun.com":
            return "Baltimore Sun";
        case "calwatchdog.com":
            return "CalWatchdog.com";
        case "chicagotribune.com":
            return "Chicago Tribune";
        case "cnet.com":
            return "CNET";
        case "edweek.org":
            return "Education Week";
        case "erraticus.co":
            return "Erraticus – A Pragmatic Approach to Ideas";
        case "eurekalert.org":
            return "EurekAlert! Science News Releases";
        case "fivethirtyeight.com":
            return "FiveThirtyEight";
        case "foreignaffairs.com":
            return "Foreign Affairs Magazine";
        case "news.mit.edu":
            return "MIT News";
        case "nationalgeographic.com":
            return "National Geographic";
        case "niemanlab.org":
            return "Nieman Lab";
        case "patch.com":
            return "Patch";
        case "pbs.org":
            return "PBS";
        case "phys.org":
            return "Phys.org";
        case "poynter.org":
            return "Poynter";
        case "sciencedaily.com":
            return "ScienceDaily";
        case "scotusblog.com":
            return "SCOTUSblog";
        case "smithsonianmag.com":
            return "Smithsonian Magazine";
        case "techcrunch.com":
            return "TechCrunch";
        case "texasmonthly.com":
            return "Texas Monthly";
        case "dispatch.com":
            return "The Columbus Dispatch";
        case "masslive.com":
            return "MassLive.com";
        case "thestate.com":
            return "The State Newspaper";
        case "thetimes.co.uk":
            return "The Times & The Sunday Times";
        case "nola.com":
            return "NOLA.com";
        case "wakeuptopolitics.com":
            return "Wake Up To Politics";
        case "wired.com":
            return "WIRED (Magazine)";
        case "cjr.org":
            return "Columbia Journalism Review";
        case "livescience.com":
            return "Live Science";
        case "nationaljournal.com":
            return "National Journal";
        case "psychologytoday.com":
            return "Psychology Today";
        case "techxplore.com":
            return "Tech Xplore";
        case "theappeal.org":
            return "The Appeal";
        case "thebellows.org":
            return "The Bellows";
        case "thecalifornian.com":
            return "The Californian News Section";
        case "clarionledger.com":
            return "The Clarion-Ledger";
        case "lincolnproject.us":
            return "The Lincoln Project";
        case "lufkindailynews.com":
            return "lufkindailynews.com";
        case "themarkup.org":
            return "The Markup";
        case "oregonlive.com":
            return "Oregon Live";
        case "seacoastonline.com":
            return "Seacoastonline.com";
        case "redandblack.com":
            return "The Red & Black";
        case "tulsaworld.com":
            return "Tulsa News";
        case "variety.com":
            return "Variety";
        case "realclearpolitics.com":
            return "RealClearPolitics";
        case "heavy.com":
            return "Heavy";
        case "houstonchronicle.com":
            return "Houston Chronicle";
        case "ivn.us":
            return "Independent Voter News";
        case "arcdigital.media":
            return "Arc Digital";
        case "barnstablepatriot.com":
            return "Barnstable Patriot";
        case "c-span.org":
            return "C-SPAN";
        case "braverangels.org":
            return "Braver Angels";
        case "news.harvard.edu":
            return "Harvard Gazette";
        case "columbiamissourian.com":
            return "Columbia Missourian";
        case "complex.com":
            return "Complex Networks";
        case "concordmonitor.com":
            return "Concord Monitor";
        case "cookpolitical.com":
            return "Cook Political Report";
        case "saturdayeveningpost.com":
            return "The Saturday Evening Post";
        case "seattletimes.com":
            return "The Seattle Time";
        case "tennessean.com":
            return "The Tennessean";
        case "elpasotimes.com":
            return "El Paso Times";
        case "eptrail.com":
            return "Estes Park Trail-Gazette";
        case "floridapolitics.com":
            return "Florida Politics";
        case "unionleader.com":
            return "unionleader.com";
        case "newjerseyglobe.com":
            return "New Jersey Globe";
        case "ballotpedia.org":
            return "Ballotpedia.org";
        case "idahostatesman.com":
            return "Idaho Statesman";
        case "civilbeat.org":
            return "Honolulu Civil Beat";
        case "georgiarecorder.com":
            return "Georgia Recorder";
        case "star-telegram.com":
            return "Fort Worth Star-Telegram";
        case "hamptonroadsmessenger.com":
            return "Hampton Roads Messenger";
        case "militarytimes.com":
            return "Military Times";
        case "newsy.com":
            return "Newsy";
        case "newtrals.com":
            return "Newtrals";
        case "pressherald.com":
            return "The Portland Press Herald";
        case "readtangle.com":
            return "Tangle News";
        case "dallasnews.com":
            return "Dallas News";
        case "arstechnica.com":
            return "Ars Technica";
        case "calmatters.org":
            return "CalMatters";
        case "azmirror.com":
            return "Arizona Mirro";
        case "si.com":
            return "Sports Illustrated";
        case "athlonsports.com":
            return "AthlonSports";
        case "nba.com":
            return "NBA.com";
        case "nhl.com":
            return "National Hockey League";
        case "nfl.com":
            return "National Football League";
        case "mlb.com":
            return "Major League Baseball";
        case "theathletic.com":
            return "The Athletic";
        case "cbssports.com":
            return "CBS Sports";
        case "bleacherreport.com":
            return "Bleacher Report";
        case "nbcsports.com":
            return "NBC Sports";
        case "mmafighting.com":
            return "MMA Fighting";
        case "skysports.com":
            return "Sky Sports";
        case "sportskeeda.com":
            return "Sportskeeda";
        case "deadspin.com":
            return "Deadspin";
        case "secsports.com":
            return "Southeastern Conference";
        case "wwos.nine.com.au":
            return "Nine's Wide World of Sports";
        case "essentiallysports.com":
            return "EssentiallySports";
        case "sport360.com":
            return "Sport360";
        case "defector.com":
            return "Defector | The last good website.";
        case "thecoldwire.com":
            return "The Cold Wire";
        case "worldinsport.com":
            return "World In Sport";
        case "codesports.com.au":
            return "CODE Sports";
        case "golfchannel.com":
            return "Golf Channel";
        case "golfweek.usatoday.com":
            return "Golfweek";
        case "nascar.com":
            return "NASCAR";
        case "motorsport.com":
            return "Motorsport.com";
        case "crash.net":
            return "Crash.Net | F1 & MotoGP | Motorsport News";
        case "formula1.com":
            return "F1 - Formula 1";
        case "racer.com":
            return "RACER";
        case "autosport.com":
            return "Autosport";
        case "espn.co.uk":
            return "ESPN";
        case "ruck.co.uk":
            return "Ruck";
        case "rugbypass.com":
            return "RugbyPass";
        case "zerotackle.com":
            return "Zero Tackle";
        case "cricketworld.com":
            return "Cricket World";
        case "espncricinfo.com":
            return "ESPNcricinfo";
        case "90min.com":
            return "90min";
        case "goal.com":
            return "GOAL";
        case "livescore.com":
            return "LiveScore";
        case "premierleague.com":
            return "Premier League";
        case "iplt20.com":
            return "IPL T20";
        case "sports.ndtv.com":
            return "NDTV Sports";
        case "caughtoffside.com":
            return "CaughtOffside";
        case "volleyballmag.com":
            return "VolleyballMag.com";
        case "atptour.com":
            return "Men's Professional Tennis";
        case "wimbledon.com":
            return "Wimbledon";
        case "thesportsrush.com":
            return "The SportsRush";
        case "cyclingweekly.com":
            return "Cycling Weekly";
        case "cycling.today":
            return "Cycling Today";
        case "badzine.net":
            return "Badzine";
        case "sbnation.com":
            return "SBNation.com";
        case "sports.yahoo.com":
            return "Yahoo! Sports";
        case "gamingtoday.com":
            return "Gaming Today";
        case "sportingnews.com":
            return "Sporting News";
        case "247sports.com":
            return "247 Sports";
        case "thebiglead.com":
            return "The Big Lead";
        case "ncaa.com":
            return "NCAA.com";
        case "wnba.com":
            return "WNBA.com";
        case "collegefootballnews.com":
            return "College Football News";
        case "foxsports.com":
            return "FOX Sports News";
        case "yardbarker.com":
            return "Yardbarker.com";
        case "underdogdynasty.com":
            return "Underdog Dynasty";
        case "pff.com":
            return "PFF";
        case "wwe.com":
            return "WWE News";
        case "wrestlinginc.com":
            return "Wrestling Inc.";
        case "ufc.com":
            return "UFC.com";
        case "mmaweekly.com":
            return "MMA Weekly";
        case "sherdog.com":
            return "Sherdog.com";
        case "pgatour.com":
            return "PGATOUR.COM";
        case "golf.com":
            return "GOLF.com";
        case "golfdigest.com":
            return "GolfDigest.com";
        case "equalizersoccer.com":
            return "Equalizer Soccer";
        case "boxingscene.com":
            return "Boxing Scene";
        case "boxingnews24.com":
            return "Boxing News 24";
        case "boxinginsider.com":
            return "BoxingInsider.com";
        case "golfmonthly.com":
            return "Golf Monthly";
        case "swimswam.com":
            return "SwimSwam";
        case "swimmingworldmagazine.com":
            return "Swimming World";
        case "usagym.org":
            return "USA Gymnastics";
        case "intlgymnast.com":
            return "International Gymnast Magazine Online";
        case "letsrun.com":
            return "LetsRun.com";
        case "usatf.org":
            return "USATF";
        case "fansided.com":
            return "FanSided";
        case "clutchpoints.com":
            return "ClutchPoints";
        case "thescore.com":
            return "theScore.com";
        case "tsn.ca":
            return "TSN | Sports News";
        case "12up.com":
            return "12Up";
        case "thesportster.com":
            return "TheSportster";
        case "thepostgame.com":
            return "ThePostGame";
        case "sporttechie.com":
            return "SportTechie";
        case "nesn.com":
            return "NESN";
        case "sportsnet.ca":
            return "Sportsnet";
        case "teamusa.org":
            return "TeamUSA";
        case "eurosport.com":
            return "Eurosport";
        case "whl.ca":
            return "WHL Network";
        case "thehockeynews.com":
            return "The Hockey News";
        case "101greatgoals.com":
            return "101 Great Goals";
        case "teamtalk.com":
            return "TEAMtalk";
        case "yahoo.com":
            return "Yahoo";
        case "businessinsider.com":
            return "Business Insider";
        case "buzzfeed.com":
            return "BuzzFeed";
        case "cbslocal.com":
            return "CBS Local";
        case "sfgate.com":
            return "SFGATE";
        case "bbc.co.uk":
            return "BBC";
        case "cosmopolitan.com":
            return "Cosmopolitan";
        case "express.co.uk":
            return "Express";
        case "aljazeera.com":
            return "Al Jazeera";
        case "theblast.com":
            return "The Blast";
        case "mlive.com":
            return "MLive.com";
        case "chron.com":
            return "Chron";
        case "thesun.co.uk":
            return "The Sun";
        case "indiatimes.com":
            return "Indiatimes.com";
        case "centurylink.net":
            return "CenturyLink";
        case "bizjournals.com":
            return "The Business Journals";
        case "sciencealert.com":
            return "ScienceAlert";
        case "telegraph.co.uk":
            return "The Telegraph";
        case "usmagazine.com":
            return "Us Weekly";
        case "financialpost.com":
            return "Financial Post";
        case "thestreet.com":
            return "TheStreet";
        case "investopedia.com":
            return "Investopedia";
        case "ibtimes.com":
            return "International Business Times";
        case "morningstar.com":
            return "Morningstar";
        case "moneymorning.com":
            return "Money Morning";
        case "business-standard.com":
            return "Business Standard";
        case "fool.com":
            return "The Motley Fool";
        case "investing.com":
            return "Investing.com";
        case "money.usnews.com":
            return "US News Money";
        case "nulltx.com":
            return "nullTX";
        case "cryptonewsz.com":
            return "CryptoNewsZ";
        case "cryptoninjas.net":
            return "CryptoNinja";
        case "ripplecoinnews.com":
            return "Ripple XRP News Today";
        case "bitrates.com":
            return "Bitrates.com";
        case "crypto-reporter.com":
            return "Crypto Reporter";
        case "globalcryptopress.com":
            return "Global Crypto Press";
        case "9to5mac.com":
            return "9to5Mac";
        case "androidauthority.com":
            return "Android Authority";
        case "appleinsider.com":
            return "AppleInsider";
        case "cheddar.com":
            return "Cheddar";
        case "news.crunchbase.com":
            return "Crunchbase News";
        case "digitaltrends.com":
            return "Digital Trends";
        case "engadget.com":
            return "Engadget";
        case "fastcompany.com":
            return "Fast Company";
        case "gamesradar.com":
            return "GamesRadar";
        case "geekwire.com":
            return "GeekWire";
        case "inc.com":
            return "Inc. Magazine";
        case "macrumors.com":
            return "MacRumors";
        case "macworld.com":
            return "Macworld";
        case "technologyreview.com":
            return "MIT Technology Review";
        case "pcmag.com":
            return "PCMag";
        case "pcworld.com":
            return "PCWorld";
        case "polygon.com":
            return "Polygon";
        case "popularmechanics.com":
            return "Popular Mechanics";
        case "searchenginejournal.com":
            return "Search Engine Journal";
        case "slashgear.com":
            return "SlashGear";
        case "techinasia.com":
            return "Tech in Asia";
        case "technical.ly":
            return "Technical.ly";
        case "techradar.com":
            return "TechRadar";
        case "thenextweb.com":
            return "TheNextWeb";
        case "zdnet.com":
            return "ZDNET";
        case "venturebeat.com":
            return "VentureBeat";
        case "gigaom.com":
            return "GigaOm";
        case "lifehacker.com":
            return "Lifehacker";
        case "computerworld.com":
            return "Computerworld";
        case "howtogeek.com":
            return "How-To Geek";
        case "techrepublic.com":
            return "TechRepublic";
        case "techspot.com":
            return "TechSpot";
        case "techtimes.com":
            return "Tech Times";
        case "extremetech.com":
            return "ExtremeTech";
        case "lifewire.com":
            return "Lifewire";
        case "pocket-lint.com":
            return "Pocket-lint";
        case "pymnts.com":
            return "PYMNTS";
        case "makeuseof.com":
            return "MakeUseOf";
        case "techhive.com":
            return "TechHive";
        case "ubergizmo.com":
            return "Ubergizmo";
        case "www.coindesk.com":
            return "CoinDesk";
        case "www.theblock.co":
            return "The Block Co";
        case "www.theblockcrypto.com":
            return "The Block";
        case "www.ccn.com":
            return "Crypto News Network";
        case "www.coinspeaker.com":
            return "Coin Speaker";
        case "chainwire.org":
            return "Chain Wire";
        case "www.gokhshteinmedia.com":
            return "Gokhstein Media";
        case "www.bnnbloomberg.ca":
            return "BNN";
        case "www.coininsider.com":
            return "Coin Insider";
        case "www.newsbtc.com":
            return "News BTC";
        case "www.cryptoglobe.com":
            return "Crypto Globe";
        case "coinnounce.com":
            return "Coinnounce";
        case "cryptoinsider.media":
            return "CryptoInsider";
        case "www.econotimes.com":
            return "Econo Times";
        case "www.cryptocurrencywire.com":
            return "Crypto News Wire";
        case "www.wu-talk.com":
            return "Wu Talk";
        case "www.finextra.com":
            return "Fin Extra";
        case "simetri.cryptobriefing.com":
            return "Simetri";
        case "blog.coinmarketcap.com":
            return "Coin Market Cap";
        case "thecryptobasic.com":
            return "The Crypto Basic";
        case "www.thecoinrepublic.com":
            return "The Coin Republic";
        case "thecoinrise.com":
            return "The Coin Rise";
        case "thebitcoinnews.com":
            return "The Bitcoin News";
        case "coincurrencynews.com":
            return "Coin Currency";
        case "cryptomining-blog.com":
            return "Crypto Mining";
        case "coinpress.io":
            return "Coin Press";
        case "www.cryptoninjas.net":
            return "Crypto Ninjas";
        case "blog.coinjar.com":
            return "Coin Jar";
        case "btcworldnews.com":
            return "BTC World News";
        case "blog.trezor.io":
            return "Trezor";
        case "botsfolio.com":
            return "Bots Folio";
        case "bitedge.com":
            return "Bit Edge";
        case "blog.flitpay.in":
            return "Flit Pay";
        case "changelly.com":
            return "Changelly";
        case "www.investorideas.com":
            return "Investor Ideas";
        case "www.bitcoinbazis.hu":
            return "Bitcoin Bazis";
        case "blog.purse.io":
            return "Purse";
        case "blog.coinspectator.com":
            return "Coin Spectator";
        case "www.ukbitcoinblog.com":
            return "UK Bitcoin Blog";
        case "blog.bitpanda.com":
            return "Bit Panda";
        case "blog.coingate.com":
            return "Coin Gate";
        case "oilblockchain.news":
            return "Oil BlockChain";
        case "blog.coinbaazar.com":
            return "Coin Baazar";
        case "bitcoinmxn.com":
            return "Bitcoin MXN";
        case "paperblockchain.com":
            return "Paper BlockChain";
        case "btctrading.wordpress.com":
            return "BTC Trading";
        case "www.crypto-news.net":
            return "Crypto News Net";
        case "bitcoinft.com":
            return "Bitcoin NFT";
        case "www.bitstarz.com":
            return "Biz Star";
        case "ethereumworldnews.com":
            return "Ethereum World News";
        case "infoonbitcoin.com":
            return "Info Bitcoin";
        case "www.cryptostache.com":
            return "Crypto Stache";
        case "www.helenabitcoinmining.com":
            return "Helena Bitcoin Mining";
        case "www.goldsilveranalyst.com":
            return "Gold Silver Analyst";
        case "bitcoin-millionaire.com":
            return "Bitcoin Millionaire";
        case "blog.zebpay.com":
            return "Zeb Pay";
        case "www.cryptocurrencynewscast.online":
            return "Cryptocurrency News Cast";
        case "newsbitcoinB47.com":
            return "News Bitcoin B4/7";
        case "hodlhard.io":
            return "Hodl Hard";
        case "bitrazzi.com":
            return "Bitrazzi";
        case "blocknewsafrica.com":
            return "Block News Africa";
        case "coinworldstory.com":
            return "Coin World Story";
        case "news.coinxhigh.com":
            return "Coin X";
        case "www.benzinga.com":
            return "Benzinga";
        case "www.ft.com":
            return "Financial Times";
        case "www.wsj.com":
            return "WSJ";
        case "www.bloomberg.com":
            return "Bloomberg";
        case "www.cnbc.com":
            return "CNBC";
        case "www.nytimes.com":
            return "NY Times";
        case "www.reuters.com":
            return "Reuters";
        case "www.thestreet.com":
            return "The Street";
        case "www.nasdaq.com":
            return "Nasdaq";
        case "www.barrons.com":
            return "Barrons";
        case "www.vice.com":
            return "Vice";
        case "www.fastcompany.com":
            return "Fast Company";
        case "www.forbes.com":
            return "Forbes";
        case "capital.com":
            return "Capital.com";
        case "www.ibtimes.com":
            return "IBT";
        case "www.financemagnates.com":
            return "Finance Magnates";
        case "www.inc.com":
            return "INC";
        case "www.crowdfundinsider.com":
            return "Crowd Fund Insider";
        case "www.marketwatch.com":
            return "Market Watch";
        case "www.investing.com":
            return "Investing";
        case "www.binance.com":
            return "Binance Blog";
        case "blog.coinbase.com":
            return "Coinbase Blog";
        case "blog.bitfinex.com":
            return "Bitfinex";
        case "aC6zcrypto.com":
            return "AZ C6 Blog";
        case "blog.huobi.com":
            return "Huobi Blog";
        case "blog.wazirx.com":
            return "WazirX";
        case "www.kucoin.com":
            return "Kucoin";
        case "www.sec.gov":
            return "SEC";
        case "www.cftc.gov":
            return "CFTC";
        case "www.federalreserve.gov":
            return "Federal Reserve";
        case "usatoday.com":
            return "USA TODAY";
        case "nytimes.com":
            return "The New York Times";
        case "latimes.com":
            return "Los Angeles Times";
        case "nypost.com":
            return "New York Post";
        case "washingtontimes.com":
            return "Washington Times";
        case "cnn.com":
            return "CNN";
        case "foxnews.com":
            return "Fox News";
        case "bloomberg.com":
            return "Bloomberg";
        case "abcnews.go.com":
            return "ABC News";
        case "alternet.org":
            return "AlterNet";
        case "buzzfeednews.com":
            return "BuzzFeed News";
        case "cbsnews.com":
            return "CBS News";
        case "thedailybeast.com":
            return "The Daily Beast";
        case "democracynow.org":
            return "Democracy Now!";
        case "huffpost.com":
            return "HuffPost";
        case "motherjones.com":
            return "Mother Jones";
        case "msnbc.com":
            return "MSNBC News";
        case "nbcnews.com":
            return "NBC News";
        case "npr.org":
            return "NPR";
        case "politico.com":
            return "POLITICO";
        case "slate.com":
            return "Slate Magazine";
        case "theatlantic.com":
            return "The Atlantic";
        case "economist.com":
            return "Economist Com";
        case "theguardian.com":
            return "The Guardian";
        case "theintercept.com":
            return "The Intercept";
        case "newyorker.com":
            return "The New Yorker";
        case "time.com":
            return "Time (magazine)";
        case "vox.com":
            return "Vox";
        case "washingtonpost.com":
            return "The Washington Post";
        case "ajc.com":
            return "The Atlanta Journal";
        case "statesman.com":
            return "Austin American-Statesman";
        case "bostonreview.net":
            return "Boston Review";
        case "bustle.com":
            return "Bustle";
        case "chicago.suntimes.com":
            return "Chicago Sun-Times";
        case "elle.com":
            return "ELLE";
        case "espn.com":
            return "ESPN";
        case "esquire.com":
            return "Esquire";
        case "gizmodo.com":
            return "Gizmodo";
        case "gq.com":
            return "GQ: Men's Fashion";
        case "hightimes.com":
            return "High Times Magazine";
        case "insider.com":
            return "Insider";
        case "jezebel.com":
            return "Jezebel";
        case "mashable.com":
            return "Mashable";
        case "miamiherald.com":
            return "Miami Herald";
        case "today.com":
            return "TODAY";
        case "nydailynews.com":
            return "New York Daily News";
        case "nymag.com":
            return "New York Magazine";
        case "newsone.com":
            return "NewsOne";
        case "people.com":
            return "People.com";
        case "politicususa.com":
            return "PoliticusUSA";
        case "refinery29.com":
            return "Refinery29";
        case "rollingstone.com":
            return "Rolling Stone";
        case "scientificamerican.com":
            return "Scientific American";
        case "socialistalternative.org":
            return "Socialist Alternative";
        case "socialistproject.ca":
            return "Socialist Project";
        case "startribune.com":
            return "Star Tribune";
        case "theadvocate.com":
            return "The Advocate";
        case "bostonglobe.com":
            return "The Boston Globe";
        case "independent.co.uk":
            return "The Independent";
        case "thenation.com":
            return "The Nation";
        case "texastribune.org":
            return "The Texas Tribune";
        case "theverge.com":
            return "The Verge";
        case "theweek.com":
            return "The Week";
        case "theskimm.com":
            return "theSkimm";
        case "archive.thinkprogress.org":
            return "ThinkProgress.org";
        case "vanityfair.com":
            return "Vanity Fair";
        case "vice.com":
            return "VICE";
        case "news.yahoo.com":
            return "Yahoo News";
        case "yesmagazine.org":
            return "YES! Magazine";
        case "arkansasonline.com":
            return "The Arkansas Democrat-Gazette";
        case "atlantablackstar.com":
            return "Atlanta Black Star";
        case "bangordailynews.com":
            return "Bangor Daily News";
        case "blackagendareport.com":
            return "Black Agenda Report";
        case "blackenterprise.com":
            return "Black Enterprise";
        case "boingboing.net":
            return "Boing Boing";
        case "publicintegrity.org":
            return "Center for Public Integrity";
        case "commondreams.org":
            return "Common Dreams";
        case "currentaffairs.org":
            return "Current Affairs";
        case "dailykos.com":
            return "Daily Kos";
        case "eastbaytimes.com":
            return "East Bay Times";
        case "counter-currents.com":
            return "Counter-Currents Publishing";
        case "freep.com":
            return "Detroit Free Press";
        case "glaad.org":
            return "GLAAD";
        case "grist.org":
            return "Grist.org";
        case "courier-journal.com":
            return "Courier-Journal";
        case "mediamatters.org":
            return "Media Matters for America";
        case "mediaite.com":
            return "Mediaite.com";
        case "newscentermaine.com":
            return "News Center Maine";
        case "propublica.org":
            return "ProPublica";
        case "rawstory.com":
            return "Raw Story";
        case "salon.com":
            return "Salon.com";
        case "politifact.com":
            return "PolitiFact";
        case "tmz.com":
            return "TMZ";
        case "teenvogue.com":
            return "Teen Vogue";
        case "seventeen.com":
            return "Seventeen Magazine";
        case "splinternews.com":
            return "Splinter News";
        case "msn.com":
            return "MSN";
        case "sfchronicle.com":
            return "San Francisco Chronicle";
        case "vulture.com":
            return "Vulture";
        case "usnews.com":
            return "U.S. News & World Report";
        case "inquirer.com":
            return "Philippine Daily Inquirer";
        case "mercurynews.com":
            return "The Mercury News";
        case "nj.com":
            return "New Jersey Real-Time News";
        case "breitbart.com":
            return "Breitbart News Network";
        case "dailymail.co.uk":
            return "Daily Mail";
        case "nationalreview.com":
            return "National Review";
        case "reason.com":
            return "Reason Magazine";
        case "spectatorworld.com":
            return "The Spectator World";
        case "theblaze.com":
            return "TheBlaze";
        case "dailycaller.com":
            return "The Daily Caller";
        case "dailywire.com":
            return "The Daily Wire";
        case "theepochtimes.com":
            return "The Epoch Times";
        case "thefederalist.com":
            return "The Federalist";
        case "washingtonexaminer.com":
            return "Washington Examiner";
        case "americas1stfreedom.org":
            return "America's 1st Freedom";
        case "amgreatness.com":
            return "American Greatness";
        case "americanthinker.com":
            return "American Thinker";
        case "bearingdrift.com":
            return "Bearing Drift";
        case "bostonherald.com":
            return "Boston Herald";
        case "cbn.com":
            return "CBN News";
        case "city-journal.org":
            return "City Journal";
        case "cnsnews.com":
            return "CNSNews";
        case "commentary.org":
            return "Commentary Magazine";
        case "conservativehq.org":
            return "ConservativeHQ";
        case "wiscnews.com":
            return "WiscNews";
        case "dailypress.com":
            return "Daily Press";
        case "drudgereport.com":
            return "DRUDGE REPORT";
        case "thefiscaltimes.com":
            return "The Fiscal Times";
        case "frontpagemag.com":
            return "FrontPage Magazine";
        case "hotair.com":
            return "HotAir";
        case "ijr.com":
            return "IJR - Independent Journal Review";
        case "infowars.com":
            return "Infowars";
        case "intellectualconservative.com":
            return "IntellectualConservative";
        case "investors.com":
            return "Investor's Business Daily";
        case "judicialwatch.org":
            return "Judicial Watch";
        case "ksl.com":
            return "KSL News";
        case "liveaction.org":
            return "Live Action";
        case "mrc.org":
            return "Media Research Center";
        case "newsbusters.org":
            return "Newsbusters";
        case "newsmax.com":
            return "Newsmax";
        case "oann.com":
            return "One America News Network";
        case "pjmedia.com":
            return "PJ Media";
        case "projectveritas.com":
            return "Project Veritas";
        default:
            return source;
    }
}

export function extractStringsFromArray(inputArray) {
    return inputArray.reduce((resultArray, item) => {
        if (typeof item === "string") {
            // If the item is a string, push it to the result array
            resultArray.push(item);
        } else if (typeof item === "object") {
            // If the item is an object with a "data" property, push its "data" property to the result array
            resultArray.push(item.data);
        }
        return resultArray;
    }, []);
}

export function transformStringsToArrayOfObjects(stringsArray) {
    return stringsArray.map((tag) => {
        const isOrg = companiesInvolvedInCrypto.some((company) => company === tag);
        const isProduct = categories.some((company) => company === tag);
        const isPerson = people.some((company) => company === tag);

        if (isOrg) {
            return {
                data: tag,
                type: "ORG",
            };
        } else if (isProduct) {
            return {
                data: tag,
                type: "PRODUCT",
            };
        } else if (isPerson) {
            return {
                data: tag,
                type: "PERSON",
            };
        }
    });
}
