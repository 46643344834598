import { proxy } from "valtio";

const global = {
    isNewsModalOpen: false,
};

const globalOverlay = {
    setZIndex: false,
};

export const globalProxy = proxy(global);
export const globalOverlayProxy = proxy(globalOverlay);
