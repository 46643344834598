import { SVGProps, memo } from "react";

function AllNews(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
            <path
                d="M4 4.585V4a1.5 1.5 0 011.5-1.5h5A1.5 1.5 0 0112 4v.585m-8 0c.156-.055.325-.085.5-.085h7c.175 0 .344.03.5.085m-8 0A1.5 1.5 0 003 6v.585m9-2A1.5 1.5 0 0113 6v.585m0 0a1.498 1.498 0 00-.5-.085h-9c-.175 0-.344.03-.5.085m10 0A1.5 1.5 0 0114 8v4a1.5 1.5 0 01-1.5 1.5h-9A1.5 1.5 0 012 12V8a1.5 1.5 0 011-1.415"
                stroke="#fff"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoAllNews = memo(AllNews);
export default MemoAllNews;
